import { User } from '@global/utils/remote-graphql-types';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import ContactFields from '@lp-root/src/components/org.contact-fields/contact-fields.component';
import { buildPostConfigRequest } from '@lp-root/src/data/request-utils/config.request';
import { Col, Grid, H1, Row, Separator } from '@web/atomic';
import { ShimmerInput } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData } from '@web/atomic/legacy/obj.form';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import React, { useCallback } from 'react';
import { SEO } from '../../components/legacy/mol.seo/seo.component';
import { GatsbyButton } from '@components/atm.button/button.component';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { useGetUser } from '@lp-root/src/components/obj.custom-hooks/user-info.hook';
import WebviewLayout from '../../components/org.layout/webview-layout.component';
import { PageProps } from '../../utils/local-types';

export interface ContatoPageFormData {
  email?: string;
  canSendEmailReminder?: string[];

  whatsapp?: string;
}

const ContatoPage: React.FunctionComponent<PageProps> = (props) => {
  const [user, userLoading] = useGetUser();
  const userName = user && user.first_name;

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Lembretes',
        }}
      />
      <Grid>
        <Row mt mb>
          <Col xs={12} sm={12} md={7} lg={7}>
            <H1>Lembretes {userName ? `de ${userName}` : ''}</H1>
          </Col>
        </Row>
        {userLoading ? (
          <>
            {Array(3)
              .fill(1)
              .map((_item, index) => (
                <Row mt mb key={index}>
                  <Col xs={12} sm={12} md={7} lg={7}>
                    <ShimmerInput />
                    <Separator />
                  </Col>
                </Row>
              ))}
          </>
        ) : (
          <ContatoFormRow user={user} />
        )}
      </Grid>
    </WebviewLayout>
  );
};

// ------------------------------------------------------------------------------------------------------
// TODO: REFACTOR - move
interface ContatoFormProps {
  user: Pick<User, 'id' | 'email' | 'can_send_email_reminder' | 'phone'>;
}

const ContatoFormRow: React.FunctionComponent<ContatoFormProps> = (props) => {
  const [submitUserContato, { loading: updateUserLoading }] = useUpdateUserRequest();

  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  const handleSubmit = useCallback(
    async (formData: FormData<ContatoPageFormData>) => {
      if (Object.keys(formData.error).length !== 0) return;
      const request = buildPostConfigRequest(props.user.id, formData.data);
      await submitUserContato(request);
      close();
    },
    [close, submitUserContato, props.user.id]
  );

  return (
    <Row>
      <Col xs={12} sm={12} md={7} lg={7}>
        <Form onSubmit={handleSubmit}>
          <ContactFields
            initialEmail={props.user.email}
            initialEmailEnabled={props.user.can_send_email_reminder}
            initialWhatsapp={props.user.phone}
          />

          <Row mb center={'xs'}>
            <Col xs={12} sm={12} md={4} lg={4}>
              <GatsbyButton type="submit" kind="primary" loading={updateUserLoading || waitCloseLoading} expanded>
                Salvar configurações
              </GatsbyButton>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default ContatoPage;

export const query = graphql`
  query ContatoPage {
    site {
      ...SiteUrl
    }
  }
`;

// ------------------------------------------------------------------------------------------------------

const useUpdateUserRequest = () => {
  const handleSuccess = useCallback(() => {
    flashDispatcherService.dispatchMessage('Informação salva com sucesso!', 'success');
  }, []);

  return usePostUsingMessenger({ url: PostUrl.UpdateUser, onSuccess: handleSuccess });
};
